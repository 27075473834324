/* Estilos específicos para ReservaFormPage */
.reserva-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.reserva-spinner-container {
    text-align: center;
    color: white;
}

.reserva-lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.reserva-lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: reserva-lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}

.reserva-lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}

.reserva-lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}

.reserva-lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}

@keyframes reserva-lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.calendario-container .turno-spacing {
    margin-bottom: 8px; /* Ajusta según sea necesario */
}

/* ==================== Optimización para la Impresión ==================== */
