/* Estilos generales para el formulario */
.concepto-form-container {
  padding: 20px;
}

/* 🟢 Botón de "Agregar Unidad Exonerada" */
.concepto-form-container .btn-add-unidad {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  background-color: #1976d2;
  color: white;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 10px;
  width: auto;
  /* Ajuste para que ocupe solo lo necesario */
  min-width: 200px;
  /* Evita que sea demasiado pequeño */
}

.concepto-form-container .btn-add-unidad i {
  font-size: 18px;
  margin-right: 5px;
}

.concepto-form-container .btn-add-unidad:hover {
  background-color: #1259a5;
}

/* 🟢 Tabla de Unidades Exoneradas */
.concepto-form-container table {
  display: flex;
  border-collapse: collapse;
  margin-top: 15px;
  background-color: white;
}

.concepto-form-container .unidad-exonerada-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
}

.concepto-form-container th,
.concepto-form-container td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

/* Encabezados de la tabla */
.concepto-form-container th {
  background-color: #f5f5f5;
  font-weight: bold;
  text-align: center;
}

/* 🟢 Mensaje cuando no hay unidades exoneradas */
.concepto-form-container .no-unidades {
  text-align: center;
  font-style: italic;
  color: grey;
  margin-top: 10px;
}

/* 🟢 Ajustes responsivos */
@media screen and (max-width: 600px) {
  .concepto-form-container table {
    font-size: 14px;
  }

  .concepto-form-container th,
  .concepto-form-container td {
    padding: 8px;
  }

  .concepto-form-container .btn-add-unidad {
    min-width: 150px;
    font-size: 14px;
  }
}