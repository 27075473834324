.custom-modal {
    background: white;
    padding: 30px;
    width: 50%;
    max-width: 500px;
    margin: auto;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    position: relative;
  }
  
  .custom-overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
/*
  .button-custom1 {
    display: block;
    width: 100%;
    margin-top: 20px;
    padding: 10px;
    font-size: 18px;
    font-weight: bold;
  }
*/  