#cont_login {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: flex-start; /* Alinea el contenido en la parte superior */
    min-height: 100vh; /* Ocupa toda la altura de la pantalla */
    background-color: #f5f5f5; /* Fondo claro */
    padding-top: 20px; /* Espacio en la parte superior */
  }
  
  .login .formulario {
    width: 100%;
    max-width: 400px; /* Ancho máximo del formulario */
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .login .input-field {
    margin-bottom: 20px;
  }
  
  .login button {
    width: 100%;
  }
  
  .login h5.center {
    margin-bottom: 20px;
    text-align: center;
    color: #333; /* Color del texto del título */
  }
  
  /*css para Spinner*/
  /* Estilos específicos para LoginPage */
.login-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.login-spinner-container {
  text-align: center;
  color: white;
}

.login-lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.login-lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: login-lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.login-lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.login-lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.login-lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes login-lds-ring {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
