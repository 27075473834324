
  /*css para Spinner*/
  /* Estilos específicos para LoginPage */
.recuperar-password-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.recuperar-password-spinner-container {
  text-align: center;
  color: white;
}

.recuperar-password-lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.recuperar-password-lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: login-lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.recuperar-password-lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.recuperar-password-lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.recuperar-password-lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes recuperar-password-lds-ring {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}
