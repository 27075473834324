.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.modal-container {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 450px;
    max-width: 90%;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-title {
    margin-bottom: 15px;
    font-size: 1.5rem;
    font-weight: bold;
}

.input-field input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.modal-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 10px;
}

.modal-buttons .btn {
    width: 45%;
}

.modal-message {
    margin-top: 10px;
    color: #666;
}

.modal-error {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}

.modal-result {
    background: #f8f8f8;
    padding: 10px;
    border-radius: 5px;
    margin-top: 10px;
}

.modal-result p {
    margin: 5px 0;
}
