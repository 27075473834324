/* Estilos específicos para la página MisRecibosAdmin */
.mis-recibos-admin-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.mis-recibos-admin-card {
    padding: 20px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.mis-recibos-admin-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.mis-recibos-admin-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 20px;
}

.mis-recibos-admin-filters .input-field {
    flex: 1;
    min-width: 200px;
}

.mis-recibos-admin-table-container {
    overflow-x: auto;
}

.mis-recibos-admin-table {
    width: 100%;
    border-collapse: collapse;
}

.mis-recibos-admin-table th,
.mis-recibos-admin-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
    text-align: left;
}

.mis-recibos-admin-table th {
    background-color: #f5f5f5;
    font-weight: bold;
}

.mis-recibos-admin-table tbody tr:hover {
    background-color: #f1f1f1;
}

.mis-recibos-admin-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.mis-recibos-admin-btn:hover {
    background-color: #0056b3;
}
