/* calendarioReservasPage.css */

/* Contenedor principal */
.calendario-container {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5; /* Fondo suave para resaltar el calendario */
    min-height: 100vh; /* Asegurar que ocupe al menos la altura de la ventana */
}

/* Título del Calendario */
.calendario-container h4 {
    margin-bottom: 20px;
    color: #1976d2; /* Azul para el título */
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

/* Leyenda de Disponibilidad */
.calendario-container .legend-container {
    display: flex;
    justify-content: flex-end; /* Alinear la leyenda a la derecha */
    align-items: center;
}

.calendario-container .legend {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.calendario-container .legend-item {
    display: flex;
    align-items: center;
    margin-left: 15px; /* Espacio entre legend items */
}

.calendario-container .legend-color {
    width: 20px;
    height: 20px;
    background-color: #388e3c; /* Verde oscuro por defecto */
    margin-right: 8px;
    border-radius: 4px; /* Esquinas ligeramente redondeadas */
}

.calendario-container .legend-color.ocupado {
    background-color: #d32f2f; /* Rojo oscuro para Reservado */
}
.calendario-container .legend-color.pasado {
    background-color: #e0e0e0; /* Gris claro */
}
.calendario-container .legend-label {
    font-size: 0.9rem;
    color: #424242;
    font-family: 'Roboto', sans-serif;
}

/* Selector de Área Común */
.calendario-container .input-field select {
    width: 100%;
    padding: 12px 15px;
    border: 1px solid #9e9e9e;
    border-radius: 4px;
    background-color: #fff;
    font-size: 1rem;
    color: #424242;
    font-family: 'Roboto', sans-serif;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.calendario-container .input-field select:focus {
    outline: none;
    border-color: #1976d2; /* Azul al enfocar */
    box-shadow: 0 0 5px rgba(25, 118, 210, 0.5);
}

/* Contenedor del Calendario */
.calendario-container .calendar-wrapper {
    width: 100%;
    max-width: 1200px; /* Aumentado para ocupar más espacio en pantallas grandes */
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
    overflow-x: auto; /* Permitir scroll horizontal si es necesario */
}

/* Estilo responsivo para el Calendario */
.calendario-container .responsive-calendar {
    width: 100% !important;
    height: auto !important;
    border: none;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
    background-color: #fff;
}

/* Estilos para los turnos en los días del calendario */

/* Contenedor de los turnos en cada celda del calendario */

.calendario-container .tile-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    text-align: center;
    padding: 4px;
    max-width: 100%;
    overflow: hidden;
}

/* Turnos como círculos */
.calendario-container .turno-circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

/* Estilos para cada turno */

.calendario-container .turno:hover {
    transform: scale(1.05);
    opacity: 0.9;
}

.calendario-container .disponible {
    background-color: #388e3c; /* Verde oscuro para disponible */
}

.calendario-container .ocupado {
    background-color: #d32f2f; /* Rojo oscuro para ocupado */
}

/* Vista resumida para móviles */
.calendario-container .summary {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.8rem;
}

.calendario-container .summary span {
    margin: 2px 0;
}

.calendario-container .disponible-count {
    color: #388e3c; /* Verde oscuro */
}

.calendario-container .ocupado-count {
    color: #d32f2f; /* Rojo oscuro */
}

@media only screen and (min-width: 601px) { /* Aplicar solo en pantallas grandes */
    .calendario-container .turno {
        padding: 3px 4px; /* Aumenta el espacio interno */
        margin: 6px 4px; /* Agrega un pequeño margen */
        display: flex; /* Permite alinear el texto correctamente */
        align-items: center; /* Asegura que el texto esté centrado verticalmente */
        justify-content: center; /* Asegura que el texto esté centrado horizontalmente */
        min-width: 80px; /* Asegura que tenga espacio suficiente */
    }
}

/* Estilo para resaltar el día actual 
.calendario-container .dia-actual {
    position: relative;
    background-color: rgba(25, 118, 210, 0.2); 
    border: 2px solid #1976d2; 
    border-radius: 10px;
    box-shadow: 0 0 8px rgba(25, 118, 210, 0.4);
}
*/
/* Indicador de día actual en la esquina
.calendario-container .dia-actual::after {
    content: 'Hoy';
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 0.75rem;
    font-weight: bold;
    color: white;
    background-color: #1976d2;
    padding: 2px 6px;
    border-radius: 6px;
}

 */
/* Ajustar el tamaño de los textos en dispositivos pequeños */
@media only screen and (max-width: 600px) {
    /*
    .calendario-container .turno {
        font-size: 0.65rem;
        padding: 2px 6px;
    }*/
    .calendario-container .turno-circle {
        width: 16px;
        height: 16px;
    }

    .calendario-container .turno {
        font-size: 0.65rem;
        width: 95%;
        max-height: 2.6em; /* Ajuste menor para pantallas pequeñas */
        line-height: 1.3em; /* Ajuste de línea */
    }

    .calendario-container .calendario-container h4 {
        font-size: 1.5rem;
    }

    .calendario-container .calendar-wrapper {
        max-width: 100%;
        padding: 0 10px;
    }

    .calendario-container .preloader-wrapper {
        margin-top: 10px;
    }

    .calendario-container .input-field select {
        padding: 10px 12px;
        font-size: 0.9rem;
    }

    .calendario-container .legend-container {
        justify-content: center; /* Centrar la leyenda en pantallas pequeñas */
    }

    .calendario-container .legend-item {
        margin-left: 10px; /* Reducir margen en pantallas pequeñas */
    }

    .calendario-container .legend-color {
        width: 16px;
        height: 16px;
    }

    .calendario-container .legend-label {
        font-size: 0.8rem;
    }
}

/* Preloader personalizado para mejor visibilidad */
.calendario-container .preloader-wrapper {
    margin-top: 20px;
}

/* Mejorar los tooltips */
.calendario-container .tooltipped {
    font-size: 0.8rem !important;
}

/* Estilos para mensajes de carga y error */
.calendario-container .center-align p {
    margin-top: 10px;
    font-size: 1rem;
    color: #424242;
}

.calendario-container .center-align .red-text {
    font-size: 1rem;
}

/* Fuente legible */
.calendario-container .react-calendar {
    font-size: 1rem;
}

.calendario-container .react-calendar__navigation button {
    color: #1976d2;
    font-weight: bold;
}

/* Día Actual */
.calendario-container .react-calendar__tile--now {
    /*background: #ffc107;*/
    color: white;
    /*border-radius: 50%;*/
}

/* Día Seleccionado */
.calendario-container .react-calendar__tile--active {
   /* background: #1976d2;*/
    color: white;
    /*border-radius: 50%;*/
}

/* Animaciones al mostrar los turnos */
@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Estilos para la barra de progreso (opcional) */
.calendario-container .progress-bar {
    width: 100%;
    background-color: #e0e0e0;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 5px;
}

.calendario-container .progress-bar__filled {
    height: 8px;
    background-color: #1976d2;
    width: 50%; /* Ajusta dinámicamente según los datos */
    transition: width 0.5s ease-in-out;
}

.calendario-container .turno-info {
    font-size: 0.7rem;
    text-align: center;
    margin-top: 2px;
    color: #424242;
}

