/* Estilo compacto para la tabla */
.compact-table td, .compact-table th {
    padding: 6px 10px !important;
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
  .recibo-detalle .input-field.small-input input {
    width: 80px;
  }
  .importe-monto {
    text-align: right;
    font-family: monospace;
    white-space: nowrap;
  }