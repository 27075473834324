/* Contenedor principal */
.user-center-container {
    max-width: 800px;
    margin: 30px auto;
    padding: 20px;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    font-family: Arial, sans-serif;
}

/* Títulos principales */
.user-center-container h2 {
    text-align: center;
    color: #007bff;
    margin-bottom: 20px;
}

/* Secciones de información */
.user-info, .building-info, .unit-info, .properties {
    padding: 15px;
    margin-bottom: 20px;
    border-radius: 8px;
    background: #f8f9fa;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Títulos de sección */
.user-info h3, .building-info h3, .unit-info h3, .properties h3 {
    font-size: 18px;
    color: #343a40;
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
    margin-bottom: 15px;
}

/* Párrafos dentro de las secciones */
.user-info p, .building-info p, .unit-info p {
    font-size: 16px;
    color: #495057;
    margin: 5px 0;
}

/* Etiquetas en negrita */
.user-info strong, .building-info strong, .unit-info strong {
    color: #000;
}

/* Fila flexible para agrupar campos relacionados en una sola línea */
.user-info .row {
    display: flex;
    justify-content: space-between;
    gap: 15px;
}

/* Cada campo de una fila ocupa el 50% del espacio disponible */
.user-info .row div {
    flex: 1;
}

/* Para asegurar que los elementos se adapten bien en móviles */
@media (max-width: 600px) {
    .user-info .row {
        flex-direction: column;
    }
}

/* Tabla de propiedades */
.properties table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    background: white;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

/* Encabezados de tabla */
.properties table thead {
    background: #007bff;
    color: white;
    font-weight: bold;
}

.properties table th, .properties table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
}

/* Estilo de filas alternas */
.properties table tbody tr:nth-child(even) {
    background: #f2f2f2;
}

/* Mensaje cuando no hay propiedades */
.properties table tbody tr td {
    color: #6c757d;
}

/* Responsive para pantallas pequeñas */
@media (max-width: 600px) {
    .user-center-container {
        width: 90%;
        padding: 15px;
    }

    .properties table th, .properties table td {
        font-size: 14px;
        padding: 8px;
    }
}
/* Contenedor del título y botón */
.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

/* Botón de regreso */
.btn-back {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    padding: 8px 12px;
    border-radius: 5px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    transition: background 0.3s;
}

.btn-back i {
    margin-right: 5px;
}

.btn-back:hover {
    background-color: #0056b3;
    cursor: pointer;
}
