/* Estilos exclusivos para el modal de selección de conceptos */
.select-concepts-modal {
  width: 80%;
  max-width: 800px;
  height: 70vh;
  margin: auto;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  z-index: 1050;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

/* Estilo específico para el overlay del modal */
.select-concepts-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1040;
}

/* Encabezado del modal con diseño diferenciado */
.select-concepts-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007bff;
  color: white;
  padding: 10px 15px;
  border-radius: 5px 5px 0 0;
  font-size: 18px;
  font-weight: bold;
}

/* Botón de cierre con diseño impactante */
.select-concepts-close-btn {
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.select-concepts-close-btn:hover {
  color: #ffcccc;
}

/* Estilos personalizados para el filtro */
.select-concepts-filter {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 10px 0;
}

.select-concepts-filter input {
  flex-grow: 1;
  padding: 8px;
  border: 2px solid #007bff;
  border-radius: 5px;
  outline: none;
}

/* Contenedor con scroll para la tabla */
.select-concepts-table-container {
  flex-grow: 1;
  max-height: 55vh;
  overflow-y: auto;
  overflow-x: auto;
  border: 2px solid #007bff;
  border-radius: 5px;
  background: #f8f9fa;
  padding: 5px;
}

/* Tabla con diseño exclusivo */
.select-concepts-table {
  width: 100%;
  border-collapse: collapse;
  background: white;
}

/* Encabezados de la tabla con un diseño destacado */
.select-concepts-table thead {
  background: #007bff;
  color: white;
  font-weight: bold;
}

.select-concepts-table th, 
.select-concepts-table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

/* Filas alternas con colores diferenciados */
.select-concepts-table tbody tr:nth-child(even) {
  background: #f2f2f2;
}

/* Botón de selección personalizado */
.select-concepts-btn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;
}

.select-concepts-btn:hover {
  background-color: #0056b3;
}
