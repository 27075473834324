.estado-pill {
    display: inline-flex;
    align-items: center;
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 0.85rem;
    font-weight: 500;
    color: #fff;
    background-color: #9e9e9e; /* fallback por si no hay match */
    text-transform: capitalize;
  }
  
  /* ícono dentro del pill */
  .estado-pill i {
    font-size: 16px;
    margin-right: 6px;
    line-height: 1;
  }
  
  /* Colores personalizados por estado */
  .estado-pill.pagado {
    background-color: #43a047; /* verde */
  }
  
  .estado-pill.pendiente {
    background-color: #fb8c00; /* naranja */
  }
  
  .estado-pill.vencido {
    background-color: #e53935; /* rojo */
  }