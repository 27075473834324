.lectura-rapida-contometro {
    text-align: center;
    padding: 20px;
    max-width: 500px;
    margin: auto;
    background: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.lectura-rapida-contometro .title {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

/* Agrupar elementos en la misma fila */
.lectura-rapida-contometro .input-row {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

/* Tamaño flexible para cada campo */
.lectura-rapida-contometro .input-group.half {
    flex: 1;
}

/* Estilos de los inputs */
.lectura-rapida-contometro .input-group {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 15px;
}

.lectura-rapida-contometro .input-group label {
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
}

.lectura-rapida-contometro .input-group input,
.lectura-rapida-contometro .input-group select,
.lectura-rapida-contometro .input-group .react-datepicker__input-container input {
    font-size: 16px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    background: #fff;
    height: 42px;
}

.lectura-rapida-contometro .input-group input:focus,
.lectura-rapida-contometro .input-group select:focus,
.lectura-rapida-contometro .input-group .react-datepicker__input-container input:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Forzar visibilidad del select */
.lectura-rapida-contometro .select-visible {
    display: block !important;
    opacity: 1 !important;
    visibility: visible !important;
    appearance: auto;
    width: 100%;
    background: #fff;
    height: 42px;
}

/* Botón de subida */
.lectura-rapida-contometro .upload-btn {
    font-size: 18px;
    padding: 12px;
    width: 100%;
    border: none;
    border-radius: 5px;
    background: #007bff;
    color: white;
    cursor: pointer;
    transition: background 0.3s ease;
}

.lectura-rapida-contometro .upload-btn:hover {
    background: #0056b3;
}

/* Vista previa de imagen */
.lectura-rapida-contometro .preview,
.lectura-rapida-contometro .uploaded {
    margin-top: 20px;
}

.lectura-rapida-contometro .preview img,
.lectura-rapida-contometro .uploaded img {
    width: 100%;
    max-height: 300px;
    border-radius: 10px;
    object-fit: cover;
    border: 2px solid #007bff;
}

/* Mensajes de error */
.lectura-rapida-contometro .error {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}

/* Barra de progreso */
.lectura-rapida-contometro .progress-container {
    margin-top: 20px;
}

.lectura-rapida-contometro progress {
    width: 100%;
    height: 10px;
    border-radius: 5px;
    overflow: hidden;
    background-color: #ddd;
}

.lectura-rapida-contometro progress::-webkit-progress-bar {
    background-color: #ddd;
}

.lectura-rapida-contometro progress::-webkit-progress-value {
    background-color: #007bff;
}

.lectura-rapida-contometro progress::-moz-progress-bar {
    background-color: #007bff;
}
