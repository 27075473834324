.mis-reservas-container {
    width: 100%;
    overflow: hidden;
}

.table-container {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
    max-height: 500px; /* Ajusta la altura según necesites */
    border: 1px solid #ddd;
}

.table-container table {
    width: 100%;
    white-space: nowrap;
}
