/* Estilos específicos para la página de cambio de contraseña */
.change-password-page {
    display: flex;
    justify-content: center; /* ✅ Centra horizontalmente */
    padding-top: 3rem; /* ✅ Da un margen superior sin centrar verticalmente */
    background-color: #f5f5f5;
    position: relative;
    min-height: 100vh; /* Mantiene el fondo completo en toda la pantalla */
  }
  
  .change-password-container {
    width: 100%;
    max-width: 450px;
    background: white;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  /* Encabezado centrado */
  .change-password-container h4 {
    text-align: center;
    margin-bottom: 1.5rem;
    font-weight: 600;
  }
  
  /* Estilizar los campos de contraseña */
  .change-password-container .input-field {
    display: flex;
    justify-content: center;
  }
  
  .change-password-container .input-field input {
    width: 60%; /* Hace que los inputs sean más pequeños */
    text-align: center; /* Centra el texto dentro del input */
    border-bottom: 2px solid #1565c0 !important;
    padding: 5px;
    font-size: 1rem;
  }
  
  /* Botón estilizado */
  .change-password-container button {
    width: 100%;
    background-color: #1565c0;
    transition: background 0.3s ease;
  }
  
  .change-password-container button:hover {
    background-color: #0d47a1;
  }
  
  /* Mensajes de éxito y error */
  .change-password-container .success-message {
    color: #2e7d32;
    text-align: center;
    font-weight: 500;
    margin-top: 1rem;
  }
  
  .change-password-container .error-message {
    color: #d32f2f;
    text-align: center;
    font-weight: 500;
    margin-top: 1rem;
  }
  
  /* Mensaje de éxito */
.success-message-container {
    padding: 1.5rem;
    text-align: center;
    border-radius: 8px;
    background-color: #e8f5e9;
  }
  
  .success-message-container p {
    font-size: 1.2rem;
    font-weight: 500;
    color: #2e7d32;
    margin-bottom: 1rem;
  }

  /* Overlay del spinner */
.change-password-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }
  
  /* Estilo del spinner */
  .change-password-spinner-container {
    text-align: center;
    color: white;
  }
  
  .change-password-lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }

  .change-password-lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: change-password-lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  
  .change-password-lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  
  .change-password-lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  
  .change-password-lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }

  @keyframes change-password-lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }