/* Estilos específicos para la página de Movimientos Bancarios */
.movimientos-bancarios-container {
    margin-top: 20px;
}

.movimientos-bancarios-card {
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.movimientos-bancarios-card-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
}

.movimientos-bancarios-input-field label {
    font-size: 14px;
    color: #666;
}

.movimientos-bancarios-input-field input,
.movimientos-bancarios-input-field select {
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 8px;
}

.movimientos-bancarios-table-container {
    margin-top: 20px;
    overflow-x: auto;
}

.movimientos-bancarios-striped tbody tr:nth-child(odd) {
    background-color: #f9f9f9;
}

.movimientos-bancarios-striped thead {
    background-color: #007bff;
    color: white;
    font-weight: bold;
}

.movimientos-bancarios-striped th, .movimientos-bancarios-striped td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.movimientos-bancarios-btn {
    margin-right: 10px;
    border-radius: 5px;
}

.movimientos-bancarios-card-action {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
}

.movimientos-bancarios-card-action .movimientos-bancarios-btn {
    background-color: #ff9800;
    color: white;
}

.movimientos-bancarios-card-action .movimientos-bancarios-btn:hover {
    background-color: #e68900;
}
